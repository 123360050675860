// ENTITY fr.hiji.metier.campaign.dto.back._360.RelanceInfoBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class RelanceInfoBODTO {

  nbRelanceInquiryNotDeployed: number | null = 0;
  nbRelanceInquiryNotAutoObs: number | null = 0;
  nbRelanceInquiryTargetNoAnswer: number | null = 0;

  constructor(init?:Partial<RelanceInfoBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back._360.RelanceInfoBODTO'] = RelanceInfoBODTO;

reverseMapping[RelanceInfoBODTO.name] = 'fr.hiji.metier.campaign.dto.back._360.RelanceInfoBODTO';

fields['fr.hiji.metier.campaign.dto.back._360.RelanceInfoBODTO']  = {
    nbRelanceInquiryNotDeployed: 'java.lang.Integer',
    nbRelanceInquiryNotAutoObs: 'java.lang.Integer',
    nbRelanceInquiryTargetNoAnswer: 'java.lang.Integer'
};
