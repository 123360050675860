// ENTITY fr.hiji.metier.reporting.entities.ema.ReportEMAConfigTemplate generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ReportEMAConfigTemplate {

  idReportEMAConfig: number | null = 0;
  idEMATemplate: number | null = 0;

  constructor(init?:Partial<ReportEMAConfigTemplate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.ema.ReportEMAConfigTemplate'] = ReportEMAConfigTemplate;

reverseMapping[ReportEMAConfigTemplate.name] = 'fr.hiji.metier.reporting.entities.ema.ReportEMAConfigTemplate';

fields['fr.hiji.metier.reporting.entities.ema.ReportEMAConfigTemplate']  = {
    idReportEMAConfig: 'java.lang.Integer',
    idEMATemplate: 'java.lang.Integer'
};
