// fr.hiji.metier.campaign.services.front.SurveyServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, MultilingualString } from '@ic2/ic2-lib';
import { LoginResponse } from '../entities/LoginResponse';
import { SurveyDTO } from '../entities/SurveyDTO';

@Injectable({
  providedIn: 'root',
})
export class SurveyService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  loginWithCode(code: string): RpcRequestBuilder<LoginResponse> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyService.loginWithCode';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(code, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.services.dto.LoginResponse');
  }

  getSurvey(idCampaign: number): RpcRequestBuilder<SurveyDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyService.getSurvey';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.campaign.dto.front.SurveyDTO');
  }

  getSurveyThanksMessage(idCampaign: number): RpcRequestBuilder<MultilingualString> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyService.getSurveyThanksMessage';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCampaign, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.sc.MultilingualString');
  }

  saveSurvey(survey: SurveyDTO, draft: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'SurveyService.saveSurvey';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(survey, 'fr.hiji.metier.campaign.dto.front.SurveyDTO', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(draft, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
