// fr.hiji.metier.certif.services.front.QuizModalityServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { ModalityQuizDTO } from '../entities/ModalityQuizDTO';
import { ModalityQuizResultDTO } from '../entities/ModalityQuizResultDTO';

@Injectable({
  providedIn: 'root',
})
export class QuizModalityService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getResult(idExam: number): RpcRequestBuilder<ModalityQuizResultDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizModalityService.getResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizResultDTO');
  }

  answerQuiz(dto: ModalityQuizDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizModalityService.answerQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getQuizModality(idExam: number): RpcRequestBuilder<ModalityQuizDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizModalityService.getQuizModality';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.certif.dto.front.modality.quiz.ModalityQuizDTO');
  }

  createEvaluation(idExam: number, score: number, total: number, succeeded: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'QuizModalityService.createEvaluation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idExam, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(score, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(total, 'java.lang.Double', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(succeeded, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
