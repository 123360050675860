// ENTITY fr.hiji.metier.referential.dto.RepositoryBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { RepositoryObservableGestureBODTO } from './RepositoryObservableGestureBODTO';
import { Repository } from './Repository';
import { Criteria } from './Criteria';

export class RepositoryBODTO {

  repository: Repository | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  observableGestures: RepositoryObservableGestureBODTO[] | null = [];
  criterias: Criteria[] | null = [];

  constructor(init?:Partial<RepositoryBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.RepositoryBODTO'] = RepositoryBODTO;

reverseMapping[RepositoryBODTO.name] = 'fr.hiji.metier.referential.dto.RepositoryBODTO';

fields['fr.hiji.metier.referential.dto.RepositoryBODTO']  = {
    repository: 'fr.hiji.metier.referential.entities.Repository',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    observableGestures: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>'
};
