// ENTITY fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizItemStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationQuizItemStatsBODTO {

  idFormationQuiz: number | null = 0;
  nameQuiz: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<FormationQuizItemStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizItemStatsBODTO'] = FormationQuizItemStatsBODTO;

reverseMapping[FormationQuizItemStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizItemStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizItemStatsBODTO']  = {
    idFormationQuiz: 'java.lang.Integer',
    nameQuiz: 'com.ic2.sc.MultilingualString'
};
