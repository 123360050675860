// ENTITY fr.hiji.metier.mif.dto.back.event.FormationEventDiffusionListBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventDiffusionList } from './FormationEventDiffusionList';

export class FormationEventDiffusionListBODTO {

  formationEventDiffusionList: FormationEventDiffusionList | null = null;
  diffusionListName: string | null = '';

  constructor(init?:Partial<FormationEventDiffusionListBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.event.FormationEventDiffusionListBODTO'] = FormationEventDiffusionListBODTO;

reverseMapping[FormationEventDiffusionListBODTO.name] = 'fr.hiji.metier.mif.dto.back.event.FormationEventDiffusionListBODTO';

fields['fr.hiji.metier.mif.dto.back.event.FormationEventDiffusionListBODTO']  = {
    formationEventDiffusionList: 'fr.hiji.metier.mif.entities.event.FormationEventDiffusionList',
    diffusionListName: 'java.lang.String'
};
