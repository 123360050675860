// fr.hiji.metier.reseau.services.back.FonctionBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter } from '@ic2/ic2-lib';
import { FonctionBODTO } from '../entities/FonctionBODTO';
import { FonctionItemBODTO } from '../entities/FonctionItemBODTO';

@Injectable({
  providedIn: 'root',
})
export class FonctionBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  fonctionNameAlreadyExists(name: string, idFonction: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionBOService.fonctionNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFonction, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getFonctionList(filter: GenericFilter): RpcRequestBuilder<ListWithCount<FonctionItemBODTO>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionBOService.getFonctionList';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  getFonction(idFonction: number): RpcRequestBuilder<FonctionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionBOService.getFonction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFonction, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.FonctionBODTO');
  }

  saveFonction(fbodto: FonctionBODTO): RpcRequestBuilder<FonctionBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionBOService.saveFonction';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(fbodto, 'fr.hiji.metier.reseau.dto.FonctionBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.reseau.dto.FonctionBODTO');
  }

  deleteAllEmptyFonctions(): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FonctionBOService.deleteAllEmptyFonctions';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

}
