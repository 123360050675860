// fr.hiji.metier.mif.services.front.FormationSessionParticipantServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { FormationSessionAcquisitionDTO } from '../entities/FormationSessionAcquisitionDTO';
import { QuizDTO } from '../entities/QuizDTO';
import { FormationSessionParticipantDTO } from '../entities/FormationSessionParticipantDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationSessionParticipantService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  saveAcquisitionObservations(dto: FormationSessionAcquisitionDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionParticipantService.saveAcquisitionObservations';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getSessionMenuParticipant(idFormation: number, idFormationSession: number): RpcRequestBuilder<QuizDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionParticipantService.getSessionMenuParticipant';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormation, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  getAcquisitionBefore(idParticipant: number, idFormationSession: number): RpcRequestBuilder<FormationSessionAcquisitionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionParticipantService.getAcquisitionBefore';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO');
  }

  getAcquisitionAfter(idParticipant: number, idFormationSession: number): RpcRequestBuilder<FormationSessionAcquisitionDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionParticipantService.getAcquisitionAfter';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO');
  }

  getDataSession(idParticipant: number, idFormationSession: number): RpcRequestBuilder<FormationSessionParticipantDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationSessionParticipantService.getDataSession';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idParticipant, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantDTO');
  }

}
