// fr.hiji.metier.ema.services.front.EMAServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EMAStep } from '../entities/EMAStep';
import { EMADTO } from '../entities/EMADTO';

@Injectable({
  providedIn: 'root',
})
export class EMAService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getEMA(idUser: number): RpcRequestBuilder<EMADTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAService.getEMA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.EMADTO');
  }

  getOldEMA(idEMA: number): RpcRequestBuilder<EMADTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAService.getOldEMA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idEMA, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.EMADTO');
  }

  saveEMA(previousStep: EMAStep, emadto: EMADTO): RpcRequestBuilder<EMADTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EMAService.saveEMA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(previousStep, 'fr.hiji.metier.ema.entities.EMAStep', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(emadto, 'fr.hiji.metier.ema.dto.front.EMADTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.ema.dto.front.EMADTO');
  }

}
