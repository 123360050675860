// ENTITY fr.hiji.metier.mif.entities.registration.DiffusionList generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class DiffusionList {

  idDiffusionList: number | null = 0;
  name: string | null = '';
  idOrganisationStorage: number | null = 0;
  idPopulationSeeing: number | null = null;
  idPopulationRegistering: number | null = null;
  withValidation: boolean | null = false;
  idPopulationValidating: number | null = null;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<DiffusionList>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.registration.DiffusionList'] = DiffusionList;

reverseMapping[DiffusionList.name] = 'fr.hiji.metier.mif.entities.registration.DiffusionList';

fields['fr.hiji.metier.mif.entities.registration.DiffusionList']  = {
    idDiffusionList: 'java.lang.Integer',
    name: 'java.lang.String',
    idOrganisationStorage: 'java.lang.Integer',
    idPopulationSeeing: 'java.lang.Integer',
    idPopulationRegistering: 'java.lang.Integer',
    withValidation: 'java.lang.Boolean',
    idPopulationValidating: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
