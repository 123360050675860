// ENTITY fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { CertificationSession } from './CertificationSession';

export class CertificationSessionDTO {

  certificationSession: CertificationSession | null = null;
  isAssessor: boolean | null = false;
  code: string | null = '';

  constructor(init?:Partial<CertificationSessionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionDTO'] = CertificationSessionDTO;

reverseMapping[CertificationSessionDTO.name] = 'fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionDTO';

fields['fr.hiji.metier.certif.dto.front.certificationsession.CertificationSessionDTO']  = {
    certificationSession: 'fr.hiji.metier.certif.entities.session.CertificationSession',
    isAssessor: 'java.lang.Boolean',
    code: 'java.lang.String'
};
