// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryTargetMessages generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class InquiryTargetMessages {

  idUser: number | null = 0;
  continueMessage: string | null = '';
  startMessage: string | null = '';
  stopMessage: string | null = '';

  constructor(init?:Partial<InquiryTargetMessages>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryTargetMessages'] = InquiryTargetMessages;

reverseMapping[InquiryTargetMessages.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryTargetMessages';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryTargetMessages']  = {
    idUser: 'java.lang.Integer',
    continueMessage: 'java.lang.String',
    startMessage: 'java.lang.String',
    stopMessage: 'java.lang.String'
};
