// ENTITY fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class CertificationStatsTagValueBODTO {

  idTag: number | null = 0;
  value: number | null = 0;
  total: number | null = 0;

  constructor(init?:Partial<CertificationStatsTagValueBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO'] = CertificationStatsTagValueBODTO;

reverseMapping[CertificationStatsTagValueBODTO.name] = 'fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO';

fields['fr.hiji.metier.certif.dto.back.CertificationStatsTagValueBODTO']  = {
    idTag: 'java.lang.Integer',
    value: 'java.lang.Float',
    total: 'java.lang.Float'
};
