// ENTITY fr.hiji.metier.campaign.dto.CampaignLiteDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { RepositoryLiteDTO } from './RepositoryLiteDTO';

export class CampaignLiteDTO {

  idCampaign: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  repositories: RepositoryLiteDTO[] | null = [];

  constructor(init?:Partial<CampaignLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.CampaignLiteDTO'] = CampaignLiteDTO;

reverseMapping[CampaignLiteDTO.name] = 'fr.hiji.metier.campaign.dto.CampaignLiteDTO';

fields['fr.hiji.metier.campaign.dto.CampaignLiteDTO']  = {
    idCampaign: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    repositories: 'java.util.List<fr.hiji.metier.referential.dto.RepositoryLiteDTO>'
};
