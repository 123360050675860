// ENTITY fr.hiji.metier.user.entities.UserFonctionPerimeterConfig generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OrganisationType } from './OrganisationType';

export class UserFonctionPerimeterConfig {

  idUserFonctionPerimeterConfig: number | null = 0;
  userFonctionName: string | null = '';
  organisationType: OrganisationType | null = null;

  constructor(init?:Partial<UserFonctionPerimeterConfig>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.entities.UserFonctionPerimeterConfig'] = UserFonctionPerimeterConfig;

reverseMapping[UserFonctionPerimeterConfig.name] = 'fr.hiji.metier.user.entities.UserFonctionPerimeterConfig';

fields['fr.hiji.metier.user.entities.UserFonctionPerimeterConfig']  = {
    idUserFonctionPerimeterConfig: 'java.lang.Integer',
    userFonctionName: 'java.lang.String',
    organisationType: 'fr.hiji.metier.reseau.entities.OrganisationType'
};
