// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleEMADTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ModuleEMADTO {

  moduleEMAEnabled: boolean | null = false;
  emaName: MultilingualString | null = new MultilingualString();
  coachEnabled: boolean | null = false;
  allowN2PlusToAuditExchanges: boolean | null = false;
  managerCanViewOtherManagersExchanges: boolean | null = false;
  managerCanViewCoachExchanges: boolean | null = false;
  coachCanViewOtherCoachsExchanges: boolean | null = false;
  linkInEmailEnabled: boolean | null = false;
  teamKPIEnabled: boolean | null = false;
  teamExchangeRecapEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleEMADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleEMADTO'] = ModuleEMADTO;

reverseMapping[ModuleEMADTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleEMADTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleEMADTO']  = {
    moduleEMAEnabled: 'java.lang.Boolean',
    emaName: 'com.ic2.sc.MultilingualString',
    coachEnabled: 'java.lang.Boolean',
    allowN2PlusToAuditExchanges: 'java.lang.Boolean',
    managerCanViewOtherManagersExchanges: 'java.lang.Boolean',
    managerCanViewCoachExchanges: 'java.lang.Boolean',
    coachCanViewOtherCoachsExchanges: 'java.lang.Boolean',
    linkInEmailEnabled: 'java.lang.Boolean',
    teamKPIEnabled: 'java.lang.Boolean',
    teamExchangeRecapEnabled: 'java.lang.Boolean'
};
