// fr.hiji.metier.referential.services.back.CriteriaBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, ListWithCount, GenericFilter, MultilingualString } from '@ic2/ic2-lib';
import { Criteria } from '../entities/Criteria';
import { CriteriaBODTO } from '../entities/CriteriaBODTO';

@Injectable({
  providedIn: 'root',
})
export class CriteriaBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getCriterias(filter: GenericFilter): RpcRequestBuilder<ListWithCount<Criteria>> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CriteriaBOService.getCriterias';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(filter, 'com.ic2.utils.filter.GenericFilter', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'com.ic2.utils.ListWithCount');
  }

  criteriaNameAlreadyExists(name: MultilingualString, idCriteria: number): RpcRequestBuilder<boolean> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CriteriaBOService.criteriaNameAlreadyExists';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(name, 'com.ic2.sc.MultilingualString', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idCriteria, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Boolean');
  }

  getCriteria(idCriteria: number): RpcRequestBuilder<CriteriaBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CriteriaBOService.getCriteria';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idCriteria, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.CriteriaBODTO');
  }

  saveCriteria(c: CriteriaBODTO): RpcRequestBuilder<CriteriaBODTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'CriteriaBOService.saveCriteria';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(c, 'fr.hiji.metier.referential.dto.CriteriaBODTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.dto.CriteriaBODTO');
  }

}
