// ENTITY fr.hiji.metier.mif.dto.front.manager.EVADTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EVA } from './EVA';

export class EVADTO {

  eva: EVA | null = null;
  acquisitionEnabled: boolean | null = false;
  allowManagerToSeeExpectations: boolean | null = false;
  expectationsParticipantEnabled: boolean | null = false;
  expectationsManagerEnabled: boolean | null = false;
  actionEnabled: boolean | null = false;
  quizQualiopiEnabled: boolean | null = false;

  constructor(init?:Partial<EVADTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.manager.EVADTO'] = EVADTO;

reverseMapping[EVADTO.name] = 'fr.hiji.metier.mif.dto.front.manager.EVADTO';

fields['fr.hiji.metier.mif.dto.front.manager.EVADTO']  = {
    eva: 'fr.hiji.metier.mif.entities.eva.EVA',
    acquisitionEnabled: 'java.lang.Boolean',
    allowManagerToSeeExpectations: 'java.lang.Boolean',
    expectationsParticipantEnabled: 'java.lang.Boolean',
    expectationsManagerEnabled: 'java.lang.Boolean',
    actionEnabled: 'java.lang.Boolean',
    quizQualiopiEnabled: 'java.lang.Boolean'
};
