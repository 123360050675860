// ENTITY fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationObservationObservableGesture } from './FormationObservationObservableGesture';
import { FormationObservationObservableGestureDTO } from './FormationObservationObservableGestureDTO';
import { FormationObservationType } from './FormationObservationType';
import { Criteria } from './Criteria';

export class FormationSessionAcquisitionDTO {

  answersAcquisitionBefore: FormationObservationObservableGesture[] | null = [];
  oogs: FormationObservationObservableGestureDTO[] | null = [];
  criterias: Criteria[] | null = [];
  idUserObserved: number | null = 0;
  idFormationSession: number | null = 0;
  type: FormationObservationType | null = null;
  idFormationObservation: number | null = 0;

  constructor(init?:Partial<FormationSessionAcquisitionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO'] = FormationSessionAcquisitionDTO;

reverseMapping[FormationSessionAcquisitionDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO';

fields['fr.hiji.metier.mif.dto.front.FormationSessionAcquisitionDTO']  = {
    answersAcquisitionBefore: 'java.util.List<fr.hiji.metier.mif.entities.observation.FormationObservationObservableGesture>',
    oogs: 'java.util.List<fr.hiji.metier.mif.dto.front.FormationObservationObservableGestureDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    idUserObserved: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    type: 'fr.hiji.metier.mif.entities.observation.FormationObservationType',
    idFormationObservation: 'java.lang.Integer'
};
