// ENTITY fr.hiji.metier.mif.dto.front.FormationLiteDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class FormationLiteDTO {

  idFormation: number | null = 0;
  imageExtension: string | null = '';
  name: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';

  constructor(init?:Partial<FormationLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.FormationLiteDTO'] = FormationLiteDTO;

reverseMapping[FormationLiteDTO.name] = 'fr.hiji.metier.mif.dto.front.FormationLiteDTO';

fields['fr.hiji.metier.mif.dto.front.FormationLiteDTO']  = {
    idFormation: 'java.lang.Integer',
    imageExtension: 'java.lang.String',
    name: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String'
};
