// fr.hiji.metier.conf.services.back.submodules.C360ModuleCfgBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder, MultilingualString } from '@ic2/ic2-lib';
import { InquiryTargetType } from '../entities/InquiryTargetType';

@Injectable({
  providedIn: 'root',
})
export class C360ModuleCfgBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  updateCampaign360FeaturePopulation(idPopulation: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCampaign360FeaturePopulation';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idPopulation, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateModuleCampaign360(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateModuleCampaign360';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCampaign360ExternalEnabled(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCampaign360ExternalEnabled';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCampaign360BackofficeRespectAnonymous(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCampaign360BackofficeRespectAnonymous';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCampaign360AllowOnlyOneAnonymousInATargetType(enabled: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCampaign360AllowOnlyOneAnonymousInATargetType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(enabled, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCampaign360InfoTargetType(type: InquiryTargetType, text: MultilingualString): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCampaign360InfoTargetType';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(type, 'fr.hiji.metier.campaign.entities._360.InquiryTargetType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(text, 'com.ic2.sc.MultilingualString', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateCustom360Allowed(allowed: boolean): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'C360ModuleCfgBOService.updateCustom360Allowed';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(allowed, 'java.lang.Boolean', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
