// ENTITY fr.hiji.metier.campaign.dto.back.CampaignRepositoryPopulationBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignRepositoryPopulation } from './CampaignRepositoryPopulation';

export class CampaignRepositoryPopulationBODTO {

  crp: CampaignRepositoryPopulation | null = null;
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;

  constructor(init?:Partial<CampaignRepositoryPopulationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.CampaignRepositoryPopulationBODTO'] = CampaignRepositoryPopulationBODTO;

reverseMapping[CampaignRepositoryPopulationBODTO.name] = 'fr.hiji.metier.campaign.dto.back.CampaignRepositoryPopulationBODTO';

fields['fr.hiji.metier.campaign.dto.back.CampaignRepositoryPopulationBODTO']  = {
    crp: 'fr.hiji.metier.campaign.entities.CampaignRepositoryPopulation',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean'
};
