// fr.hiji.metier.user.services.front.GroupServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { GroupType } from '../entities/GroupType';
import { GroupDTO } from '../entities/GroupDTO';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  getGroup(groupType: GroupType, idGroup: number): RpcRequestBuilder<GroupDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GroupService.getGroup';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(groupType, 'fr.hiji.metier.user.dto.front.GroupType', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idGroup, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.user.dto.front.GroupDTO');
  }

  getGroups(): RpcRequestBuilder<GroupDTO[]> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GroupService.getGroups';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.util.List');
  }

  saveGroup(idGroup: number, groupName: string, users: number[]): RpcRequestBuilder<number> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GroupService.saveGroup';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idGroup, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(groupName, 'java.lang.String', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(users, 'java.util.List<java.lang.Integer>', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.lang.Integer');
  }

  deleteGroup(idGroup: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'GroupService.deleteGroup';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idGroup, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
