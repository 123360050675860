// ENTITY fr.hiji.metier.reporting.dto.back.Report180ConfigLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Report180Config } from './Report180Config';

export class Report180ConfigLiteBODTO {

  config: Report180Config | null = null;
  campaignNames: MultilingualString[] | null = [];

  constructor(init?:Partial<Report180ConfigLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.Report180ConfigLiteBODTO'] = Report180ConfigLiteBODTO;

reverseMapping[Report180ConfigLiteBODTO.name] = 'fr.hiji.metier.reporting.dto.back.Report180ConfigLiteBODTO';

fields['fr.hiji.metier.reporting.dto.back.Report180ConfigLiteBODTO']  = {
    config: 'fr.hiji.metier.reporting.entities._180.Report180Config',
    campaignNames: 'java.util.List<com.ic2.sc.MultilingualString>'
};
