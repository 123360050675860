// ENTITY fr.hiji.metier.certif.dto.back.stats.filters.CertificationSessionStatsLiteDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class CertificationSessionStatsLiteDTO {

  idCertification: number | null = 0;
  certificationName: MultilingualString | null = new MultilingualString();
  idCertificationSession: number | null = 0;
  certificationSessionName: string | null = '';
  startDate: Date | null = null;
  endDate: Date | null = null;

  constructor(init?:Partial<CertificationSessionStatsLiteDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.filters.CertificationSessionStatsLiteDTO'] = CertificationSessionStatsLiteDTO;

reverseMapping[CertificationSessionStatsLiteDTO.name] = 'fr.hiji.metier.certif.dto.back.stats.filters.CertificationSessionStatsLiteDTO';

fields['fr.hiji.metier.certif.dto.back.stats.filters.CertificationSessionStatsLiteDTO']  = {
    idCertification: 'java.lang.Integer',
    certificationName: 'com.ic2.sc.MultilingualString',
    idCertificationSession: 'java.lang.Integer',
    certificationSessionName: 'java.lang.String',
    startDate: 'java.time.LocalDate',
    endDate: 'java.time.LocalDate'
};
