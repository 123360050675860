// ENTITY fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { Report180ConfigCampaignBODTO } from './Report180ConfigCampaignBODTO';
import { Report180ConfigDepartmentBODTO } from './Report180ConfigDepartmentBODTO';
import { Report180Config } from './Report180Config';

export class Report180ConfigBODTO {

  config: Report180Config | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  campaigns: Report180ConfigCampaignBODTO[] | null = [];
  services: Report180ConfigDepartmentBODTO[] | null = [];

  constructor(init?:Partial<Report180ConfigBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO'] = Report180ConfigBODTO;

reverseMapping[Report180ConfigBODTO.name] = 'fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO';

fields['fr.hiji.metier.reporting.dto.back.Report180ConfigBODTO']  = {
    config: 'fr.hiji.metier.reporting.entities._180.Report180Config',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    campaigns: 'java.util.List<fr.hiji.metier.reporting.dto.back.Report180ConfigCampaignBODTO>',
    services: 'java.util.List<fr.hiji.metier.reporting.dto.back.Report180ConfigDepartmentBODTO>'
};
