// ENTITY fr.hiji.metier.mif.entities.registration.Registration generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { RegistrationStatus } from './RegistrationStatus';

export class Registration {

  idRegistration: number | null = 0;
  idFormationEvent: number | null = null;
  idFormationSession: number | null = null;
  idUser: number | null = 0;
  idUserRegisterer: number | null = 0;
  idUserValidator: number | null = null;
  idDiffusionListIncomming: number | null = null;
  registrationDate: Date | null = null;
  validationDate: Date | null = null;
  lastUpdateDate: Date | null = null;
  registeredDirectlyInQueue: boolean | null = false;
  status: RegistrationStatus | null = null;

  constructor(init?:Partial<Registration>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.registration.Registration'] = Registration;

reverseMapping[Registration.name] = 'fr.hiji.metier.mif.entities.registration.Registration';

fields['fr.hiji.metier.mif.entities.registration.Registration']  = {
    idRegistration: 'java.lang.Integer',
    idFormationEvent: 'java.lang.Integer',
    idFormationSession: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    idUserRegisterer: 'java.lang.Integer',
    idUserValidator: 'java.lang.Integer',
    idDiffusionListIncomming: 'java.lang.Integer',
    registrationDate: 'java.time.Instant',
    validationDate: 'java.time.Instant',
    lastUpdateDate: 'java.time.Instant',
    registeredDirectlyInQueue: 'java.lang.Boolean',
    status: 'fr.hiji.metier.mif.entities.registration.RegistrationStatus'
};
