// ENTITY fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionOuverteStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { QuestionFlatTag } from './QuestionFlatTag';

export class FormationQuizQuestionOuverteStatsBODTO {

  idQuestion: number | null = 0;
  question: MultilingualString | null = new MultilingualString();
  deletedFromQuiz: boolean | null = false;
  tags: QuestionFlatTag[] | null = [];

  constructor(init?:Partial<FormationQuizQuestionOuverteStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionOuverteStatsBODTO'] = FormationQuizQuestionOuverteStatsBODTO;

reverseMapping[FormationQuizQuestionOuverteStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionOuverteStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.quiz.FormationQuizQuestionOuverteStatsBODTO']  = {
    idQuestion: 'java.lang.Integer',
    question: 'com.ic2.sc.MultilingualString',
    deletedFromQuiz: 'java.lang.Boolean',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionFlatTag>'
};
