// ENTITY fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveySkillStatDTO {

  idTag: number | null = 0;
  avg: number | null = null;

  constructor(init?:Partial<SurveySkillStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO'] = SurveySkillStatDTO;

reverseMapping[SurveySkillStatDTO.name] = 'fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO';

fields['fr.hiji.metier.campaign.dto.front.SurveySkillStatDTO']  = {
    idTag: 'java.lang.Integer',
    avg: 'java.lang.Double'
};
