// ENTITY fr.hiji.metier.conf.dto.submodules.ModuleTeamBoardDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModuleTeamBoardDTO {

  moduleTeamBoardEnabled: boolean | null = false;

  constructor(init?:Partial<ModuleTeamBoardDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.conf.dto.submodules.ModuleTeamBoardDTO'] = ModuleTeamBoardDTO;

reverseMapping[ModuleTeamBoardDTO.name] = 'fr.hiji.metier.conf.dto.submodules.ModuleTeamBoardDTO';

fields['fr.hiji.metier.conf.dto.submodules.ModuleTeamBoardDTO']  = {
    moduleTeamBoardEnabled: 'java.lang.Boolean'
};
