// ENTITY fr.hiji.metier.mif.dto.back.stats.business.BusinessMIFStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class BusinessMIFStatsBODTO {

  nbExpectations: number | null = 0;
  averageExpectations: number | null = null;

  constructor(init?:Partial<BusinessMIFStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.stats.business.BusinessMIFStatsBODTO'] = BusinessMIFStatsBODTO;

reverseMapping[BusinessMIFStatsBODTO.name] = 'fr.hiji.metier.mif.dto.back.stats.business.BusinessMIFStatsBODTO';

fields['fr.hiji.metier.mif.dto.back.stats.business.BusinessMIFStatsBODTO']  = {
    nbExpectations: 'java.lang.Integer',
    averageExpectations: 'java.lang.Double'
};
