// ENTITY fr.hiji.metier.campaign.dto.back.stats.CampaignStatsDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SkillStatBODTO } from './SkillStatBODTO';
import { ManagerStatDTO } from './ManagerStatDTO';
import { Campaign } from './Campaign';
import { EtablissementStatsBODTO } from './EtablissementStatsBODTO';
import { ExchangePerWeekDTO } from './ExchangePerWeekDTO';
import { ObservableGestureStatDTO } from './ObservableGestureStatDTO';
import { SollicitationPerTargetTypeBODTO } from './SollicitationPerTargetTypeBODTO';
import { Criteria } from './Criteria';
import { KeyGestureStatDTO } from './KeyGestureStatDTO';
import { ParticipantPerDayStatBODTO } from './ParticipantPerDayStatBODTO';

export class CampaignStatsDTO {

  campaign: Campaign | null = null;
  nbObservations: number | null = 0;
  nbTotalObservations: number | null = 0;
  nbAutoObservations: number | null = 0;
  nbTotalAutoObservations: number | null = 0;
  nbExchanges: number | null = 0;
  nbTotalExchanges: number | null = 0;
  nbActiveUsers: number | null = 0;
  nbTotalUsers: number | null = 0;
  exchangePerWeeks: ExchangePerWeekDTO[] | null = [];
  keyGesturesStats: KeyGestureStatDTO[] | null = [];
  skillStats: SkillStatBODTO[] | null = [];
  observableGestures: ObservableGestureStatDTO[] | null = [];
  criterias: Criteria[] | null = [];
  managers: ManagerStatDTO[] | null = [];
  etablissements: EtablissementStatsBODTO[] | null = [];
  nbTargetUser: number | null = 0;
  nbMiniUserToShowStats: number | null = 0;
  nbSollicitationsAnswered: number | null = 0;
  nbSollicitationsTotal: number | null = 0;
  sollicitationsPerTargetType: SollicitationPerTargetTypeBODTO[] | null = [];
  participantPerDay: ParticipantPerDayStatBODTO[] | null = [];

  constructor(init?:Partial<CampaignStatsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.stats.CampaignStatsDTO'] = CampaignStatsDTO;

reverseMapping[CampaignStatsDTO.name] = 'fr.hiji.metier.campaign.dto.back.stats.CampaignStatsDTO';

fields['fr.hiji.metier.campaign.dto.back.stats.CampaignStatsDTO']  = {
    campaign: 'fr.hiji.metier.campaign.entities.Campaign',
    nbObservations: 'java.lang.Integer',
    nbTotalObservations: 'java.lang.Integer',
    nbAutoObservations: 'java.lang.Integer',
    nbTotalAutoObservations: 'java.lang.Integer',
    nbExchanges: 'java.lang.Integer',
    nbTotalExchanges: 'java.lang.Integer',
    nbActiveUsers: 'java.lang.Integer',
    nbTotalUsers: 'java.lang.Integer',
    exchangePerWeeks: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.ExchangePerWeekDTO>',
    keyGesturesStats: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.KeyGestureStatDTO>',
    skillStats: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.SkillStatBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.ObservableGestureStatDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    managers: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.ManagerStatDTO>',
    etablissements: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.EtablissementStatsBODTO>',
    nbTargetUser: 'java.lang.Integer',
    nbMiniUserToShowStats: 'java.lang.Integer',
    nbSollicitationsAnswered: 'java.lang.Integer',
    nbSollicitationsTotal: 'java.lang.Integer',
    sollicitationsPerTargetType: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.SollicitationPerTargetTypeBODTO>',
    participantPerDay: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.ParticipantPerDayStatBODTO>'
};
