// ENTITY fr.hiji.metier.ema.dto.back.stats.EMAObservableGestureStatDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { OGStatPerObservationTypeBODTO } from './OGStatPerObservationTypeBODTO';
import { ObservableGestureFlatTag } from './ObservableGestureFlatTag';

export class EMAObservableGestureStatDTO {

  idObservableGesture: number | null = 0;
  idCriteria: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  nbMastered: number | null = 0;
  nbMightBeUsefulLater: number | null = 0;
  nbNonApplicable: number | null = 0;
  nb: number | null = 0;
  avg: number | null = null;
  nbFirstHalf: number | null = 0;
  avgFirstHalf: number | null = null;
  nbSecondHalf: number | null = 0;
  avgSecondHalf: number | null = null;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  nbNps0: number | null = 0;
  nbNps10: number | null = 0;
  nbNps20: number | null = 0;
  nbNps30: number | null = 0;
  nbNps40: number | null = 0;
  nbNps50: number | null = 0;
  nbNps60: number | null = 0;
  nbNps70: number | null = 0;
  nbNps80: number | null = 0;
  nbNps90: number | null = 0;
  nbNps100: number | null = 0;
  nbObs: number | null = 0;
  avgObs: number | null = null;
  nbObsFirstHalf: number | null = 0;
  avgObsFirstHalf: number | null = null;
  nbObsSecondHalf: number | null = 0;
  avgObsSecondHalf: number | null = null;
  nbAutoObs: number | null = 0;
  avgAutoObs: number | null = null;
  nbAutoObsFirstHalf: number | null = 0;
  avgAutoObsFirstHalf: number | null = null;
  nbAutoObsSecondHalf: number | null = 0;
  avgAutoObsSecondHalf: number | null = null;
  tags: ObservableGestureFlatTag[] | null = [];
  obsTypes: OGStatPerObservationTypeBODTO[] | null = [];

  constructor(init?:Partial<EMAObservableGestureStatDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.stats.EMAObservableGestureStatDTO'] = EMAObservableGestureStatDTO;

reverseMapping[EMAObservableGestureStatDTO.name] = 'fr.hiji.metier.ema.dto.back.stats.EMAObservableGestureStatDTO';

fields['fr.hiji.metier.ema.dto.back.stats.EMAObservableGestureStatDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    idCriteria: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    nbMastered: 'java.lang.Integer',
    nbMightBeUsefulLater: 'java.lang.Integer',
    nbNonApplicable: 'java.lang.Integer',
    nb: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbFirstHalf: 'java.lang.Integer',
    avgFirstHalf: 'java.lang.Double',
    nbSecondHalf: 'java.lang.Integer',
    avgSecondHalf: 'java.lang.Double',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    nbNps0: 'java.lang.Integer',
    nbNps10: 'java.lang.Integer',
    nbNps20: 'java.lang.Integer',
    nbNps30: 'java.lang.Integer',
    nbNps40: 'java.lang.Integer',
    nbNps50: 'java.lang.Integer',
    nbNps60: 'java.lang.Integer',
    nbNps70: 'java.lang.Integer',
    nbNps80: 'java.lang.Integer',
    nbNps90: 'java.lang.Integer',
    nbNps100: 'java.lang.Integer',
    nbObs: 'java.lang.Integer',
    avgObs: 'java.lang.Double',
    nbObsFirstHalf: 'java.lang.Integer',
    avgObsFirstHalf: 'java.lang.Double',
    nbObsSecondHalf: 'java.lang.Integer',
    avgObsSecondHalf: 'java.lang.Double',
    nbAutoObs: 'java.lang.Integer',
    avgAutoObs: 'java.lang.Double',
    nbAutoObsFirstHalf: 'java.lang.Integer',
    avgAutoObsFirstHalf: 'java.lang.Double',
    nbAutoObsSecondHalf: 'java.lang.Integer',
    avgAutoObsSecondHalf: 'java.lang.Double',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.ObservableGestureFlatTag>',
    obsTypes: 'java.util.List<fr.hiji.metier.campaign.dto.back.stats.OGStatPerObservationTypeBODTO>'
};
