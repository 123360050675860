// ENTITY fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionParticipantAction } from './FormationSessionParticipantAction';
import { Criteria } from './Criteria';

export class FormationSessionParticipantObservationsActionsDTO {

  actions: FormationSessionParticipantAction[] | null = [];
  idFormationSession: number | null = 0;
  criteria: Criteria | null = null;

  constructor(init?:Partial<FormationSessionParticipantObservationsActionsDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO'] = FormationSessionParticipantObservationsActionsDTO;

reverseMapping[FormationSessionParticipantObservationsActionsDTO.name] = 'fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO';

fields['fr.hiji.metier.mif.dto.front.participant.FormationSessionParticipantObservationsActionsDTO']  = {
    actions: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionParticipantAction>',
    idFormationSession: 'java.lang.Integer',
    criteria: 'fr.hiji.metier.referential.entities.Criteria'
};
