// ENTITY fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { ObservationObservableGesturesDTO } from './ObservationObservableGesturesDTO';
import { InquiryTargetType } from './InquiryTargetType';
import { Criteria } from './Criteria';

export class InquiryObservationDTO {

  user: UserFuncAvatarDTO | null = null;
  idInquiry: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  hideSkill: boolean | null = false;
  randomizeOrder: boolean | null = false;
  anonymous: boolean | null = false;
  targetType: InquiryTargetType | null = null;
  continueMessage: string | null = '';
  startMessage: string | null = '';
  stopMessage: string | null = '';
  idObservation: number | null = 0;
  observableGestures: ObservationObservableGesturesDTO[] | null = [];
  criterias: Criteria[] | null = [];
  startAnsweringDate: Date | null = null;
  readOnly: boolean | null = false;
  forceShowDescription: boolean | null = false;

  constructor(init?:Partial<InquiryObservationDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO'] = InquiryObservationDTO;

reverseMapping[InquiryObservationDTO.name] = 'fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO';

fields['fr.hiji.metier.campaign.dto.front._360.InquiryObservationDTO']  = {
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    idInquiry: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    hideSkill: 'java.lang.Boolean',
    randomizeOrder: 'java.lang.Boolean',
    anonymous: 'java.lang.Boolean',
    targetType: 'fr.hiji.metier.campaign.entities._360.InquiryTargetType',
    continueMessage: 'java.lang.String',
    startMessage: 'java.lang.String',
    stopMessage: 'java.lang.String',
    idObservation: 'java.lang.Integer',
    observableGestures: 'java.util.List<fr.hiji.metier.campaign.dto.front.ObservationObservableGesturesDTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    startAnsweringDate: 'java.time.Instant',
    readOnly: 'java.lang.Boolean',
    forceShowDescription: 'java.lang.Boolean'
};
