// ENTITY fr.hiji.metier.user.dto.front.KpiDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { EMAObjective } from './EMAObjective';
import { EMAObjectiveResult } from './EMAObjectiveResult';

export class KpiDTO {

  objective: EMAObjective | null = null;
  results: EMAObjectiveResult[] | null = [];

  constructor(init?:Partial<KpiDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.front.KpiDTO'] = KpiDTO;

reverseMapping[KpiDTO.name] = 'fr.hiji.metier.user.dto.front.KpiDTO';

fields['fr.hiji.metier.user.dto.front.KpiDTO']  = {
    objective: 'fr.hiji.metier.ema.entities.objectif.EMAObjective',
    results: 'java.util.List<fr.hiji.metier.ema.entities.objectif.EMAObjectiveResult>'
};
