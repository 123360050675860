// ENTITY fr.hiji.metier.ema.dto.back.EMATemplateBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMATemplate } from './EMATemplate';
import { EMAObservableGestureBODTO } from './EMAObservableGestureBODTO';
import { EMAObjective } from './EMAObjective';
import { EMATemplateRepositoryBODTO } from './EMATemplateRepositoryBODTO';
import { Criteria } from './Criteria';
import { EMATemplateEnabledStep } from './EMATemplateEnabledStep';

export class EMATemplateBODTO {

  emaTemplate: EMATemplate | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  populationName: MultilingualString | null = new MultilingualString();
  populationDel: boolean | null = false;
  emaTemplateRepositories: EMATemplateRepositoryBODTO[] | null = [];
  observableGestures: EMAObservableGestureBODTO[] | null = [];
  criterias: Criteria[] | null = [];
  enabledSteps: EMATemplateEnabledStep[] | null = [];
  objectives: EMAObjective[] | null = [];

  constructor(init?:Partial<EMATemplateBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.back.EMATemplateBODTO'] = EMATemplateBODTO;

reverseMapping[EMATemplateBODTO.name] = 'fr.hiji.metier.ema.dto.back.EMATemplateBODTO';

fields['fr.hiji.metier.ema.dto.back.EMATemplateBODTO']  = {
    emaTemplate: 'fr.hiji.metier.ema.entities.template.EMATemplate',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    populationName: 'com.ic2.sc.MultilingualString',
    populationDel: 'java.lang.Boolean',
    emaTemplateRepositories: 'java.util.List<fr.hiji.metier.ema.dto.back.EMATemplateRepositoryBODTO>',
    observableGestures: 'java.util.List<fr.hiji.metier.ema.dto.back.EMAObservableGestureBODTO>',
    criterias: 'java.util.List<fr.hiji.metier.referential.entities.Criteria>',
    enabledSteps: 'java.util.List<fr.hiji.metier.ema.entities.template.EMATemplateEnabledStep>',
    objectives: 'java.util.List<fr.hiji.metier.ema.entities.objectif.EMAObjective>'
};
