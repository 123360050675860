// ENTITY fr.hiji.metier.reporting.entities.survey.SurveyReport generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { SurveyReportFilter } from './SurveyReportFilter';

export class SurveyReport {

  idSurveyReport: number | null = 0;
  idCampaign: number | null = 0;
  filter: SurveyReportFilter | null = null;
  reportName: string | null = '';
  cleanName: string | null = '';
  nbParticipants: number | null = 0;
  nbReponses: number | null = 0;
  creationDate: Date | null = null;

  constructor(init?:Partial<SurveyReport>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities.survey.SurveyReport'] = SurveyReport;

reverseMapping[SurveyReport.name] = 'fr.hiji.metier.reporting.entities.survey.SurveyReport';

fields['fr.hiji.metier.reporting.entities.survey.SurveyReport']  = {
    idSurveyReport: 'java.lang.Integer',
    idCampaign: 'java.lang.Integer',
    filter: 'fr.hiji.metier.reporting.entities.survey.SurveyReportFilter',
    reportName: 'java.lang.String',
    cleanName: 'java.lang.String',
    nbParticipants: 'java.lang.Integer',
    nbReponses: 'java.lang.Integer',
    creationDate: 'java.time.Instant'
};
