// ENTITY fr.hiji.metier.campaign.entities.survey.SurveyRelance generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HijiEmail } from './HijiEmail';

export class SurveyRelance {

  idCampaign: number | null = 0;
  email: HijiEmail | null = null;
  relanceDate: Date | null = null;
  relanceDone: boolean | null = false;
  onlyNotAnswered: boolean | null = false;

  constructor(init?:Partial<SurveyRelance>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.entities.survey.SurveyRelance'] = SurveyRelance;

reverseMapping[SurveyRelance.name] = 'fr.hiji.metier.campaign.entities.survey.SurveyRelance';

fields['fr.hiji.metier.campaign.entities.survey.SurveyRelance']  = {
    idCampaign: 'java.lang.Integer',
    email: 'fr.hiji.metier.tools.email.HijiEmail',
    relanceDate: 'java.time.Instant',
    relanceDone: 'java.lang.Boolean',
    onlyNotAnswered: 'java.lang.Boolean'
};
