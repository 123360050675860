// ENTITY fr.hiji.metier.ema.entities.EMAHasRepository generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class EMAHasRepository {

  idEMA: number | null = 0;
  idRepository: number | null = 0;

  constructor(init?:Partial<EMAHasRepository>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.entities.EMAHasRepository'] = EMAHasRepository;

reverseMapping[EMAHasRepository.name] = 'fr.hiji.metier.ema.entities.EMAHasRepository';

fields['fr.hiji.metier.ema.entities.EMAHasRepository']  = {
    idEMA: 'java.lang.Integer',
    idRepository: 'java.lang.Integer'
};
