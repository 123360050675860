// ENTITY fr.hiji.metier.tools.email.EmailBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Email } from './Email';

export class EmailBODTO {

  email: Email | null = null;
  lastTemplateSubjects: MultilingualString | null = new MultilingualString();
  lastTemplateContent: MultilingualString | null = new MultilingualString();
  userUpdater: string | null = '';
  lastVersion: number | null = 0;

  constructor(init?:Partial<EmailBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.tools.email.EmailBODTO'] = EmailBODTO;

reverseMapping[EmailBODTO.name] = 'fr.hiji.metier.tools.email.EmailBODTO';

fields['fr.hiji.metier.tools.email.EmailBODTO']  = {
    email: 'fr.hiji.metier.tools.email.Email',
    lastTemplateSubjects: 'com.ic2.sc.MultilingualString',
    lastTemplateContent: 'com.ic2.sc.MultilingualString',
    userUpdater: 'java.lang.String',
    lastVersion: 'java.lang.Integer'
};
