// ENTITY fr.hiji.metier.opinion.dto.back.OpinionQuestionBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionQuestion } from './OpinionQuestion';
import { NbAnswerPerOpinionTypeBODTO } from './NbAnswerPerOpinionTypeBODTO';
import { NbAnswerPerOpinionTypePerAnswerValBODTO } from './NbAnswerPerOpinionTypePerAnswerValBODTO';

export class OpinionQuestionBODTO {

  opinionQuestion: OpinionQuestion | null = null;
  answers: NbAnswerPerOpinionTypePerAnswerValBODTO[] | null = [];
  nbAnswersPerOpinion: NbAnswerPerOpinionTypeBODTO[] | null = [];

  constructor(init?:Partial<OpinionQuestionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.dto.back.OpinionQuestionBODTO'] = OpinionQuestionBODTO;

reverseMapping[OpinionQuestionBODTO.name] = 'fr.hiji.metier.opinion.dto.back.OpinionQuestionBODTO';

fields['fr.hiji.metier.opinion.dto.back.OpinionQuestionBODTO']  = {
    opinionQuestion: 'fr.hiji.metier.opinion.entities.OpinionQuestion',
    answers: 'java.util.List<fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypePerAnswerValBODTO>',
    nbAnswersPerOpinion: 'java.util.List<fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypeBODTO>'
};
