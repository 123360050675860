// DB ENUM fr.hiji.metier.mif.entities.registration.RegistrationStatus generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class RegistrationStatus {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly PENDING = new RegistrationStatus(1, "En attente", "PENDING");
  static readonly QUEUE = new RegistrationStatus(2, "En file d'attente", "QUEUE");
  static readonly VALIDATED = new RegistrationStatus(3, "Validé", "VALIDATED");
  static readonly REMOVED = new RegistrationStatus(4, "Supprimé", "REMOVED");
  static readonly REFUSED = new RegistrationStatus(5, "Refusé", "REFUSED");
  static readonly UNREGISTER = new RegistrationStatus(6, "Désinscrit", "UNREGISTER");

  static readonly values = [
    RegistrationStatus.PENDING,
 
    RegistrationStatus.QUEUE,
 
    RegistrationStatus.VALIDATED,
 
    RegistrationStatus.REMOVED,
 
    RegistrationStatus.REFUSED,
 
    RegistrationStatus.UNREGISTER
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of RegistrationStatus.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of RegistrationStatus.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.mif.entities.registration.RegistrationStatus'] = RegistrationStatus;
reverseMapping[RegistrationStatus.name] = 'fr.hiji.metier.mif.entities.registration.RegistrationStatus';
