// ENTITY fr.hiji.metier.reseau.dto.PopulationBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { PopulationFilterBODTO } from './PopulationFilterBODTO';
import { Population } from './Population';
import { UserLiteDTO } from './UserLiteDTO';

export class PopulationBODTO {

  population: Population | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';
  users: UserLiteDTO[] | null = [];
  filters: PopulationFilterBODTO[] | null = [];

  constructor(init?:Partial<PopulationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.dto.PopulationBODTO'] = PopulationBODTO;

reverseMapping[PopulationBODTO.name] = 'fr.hiji.metier.reseau.dto.PopulationBODTO';

fields['fr.hiji.metier.reseau.dto.PopulationBODTO']  = {
    population: 'fr.hiji.metier.reseau.entities.Population',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String',
    users: 'java.util.List<fr.hiji.metier.user.dto.UserLiteDTO>',
    filters: 'java.util.List<fr.hiji.metier.reseau.dto.PopulationFilterBODTO>'
};
