// ENTITY fr.hiji.metier.certif.dto.back.modality.ModalityBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { ModalityRoleplayBODTO } from './ModalityRoleplayBODTO';
import { ModalityInterviewBODTO } from './ModalityInterviewBODTO';
import { ModalityQuizBODTO } from './ModalityQuizBODTO';
import { Modality } from './Modality';

export class ModalityBODTO {

  modality: Modality | null = null;
  quiz: ModalityQuizBODTO | null = null;
  roleplay: ModalityRoleplayBODTO | null = null;
  interview: ModalityInterviewBODTO | null = null;
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<ModalityBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.modality.ModalityBODTO'] = ModalityBODTO;

reverseMapping[ModalityBODTO.name] = 'fr.hiji.metier.certif.dto.back.modality.ModalityBODTO';

fields['fr.hiji.metier.certif.dto.back.modality.ModalityBODTO']  = {
    modality: 'fr.hiji.metier.certif.entities.modality.Modality',
    quiz: 'fr.hiji.metier.certif.dto.back.modality.quiz.ModalityQuizBODTO',
    roleplay: 'fr.hiji.metier.certif.dto.back.modality.roleplay.ModalityRoleplayBODTO',
    interview: 'fr.hiji.metier.certif.dto.back.modality.interview.ModalityInterviewBODTO',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
