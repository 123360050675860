// ENTITY fr.hiji.metier.reporting.dto.pdf180.CampaignPodium generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { PodiumOrderedGesteObservable } from './PodiumOrderedGesteObservable';

export class CampaignPodium {

  campaignName: string | null = '';
  top3: PodiumOrderedGesteObservable[] | null = [];
  flop3: PodiumOrderedGesteObservable[] | null = [];

  constructor(init?:Partial<CampaignPodium>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.dto.pdf180.CampaignPodium'] = CampaignPodium;

reverseMapping[CampaignPodium.name] = 'fr.hiji.metier.reporting.dto.pdf180.CampaignPodium';

fields['fr.hiji.metier.reporting.dto.pdf180.CampaignPodium']  = {
    campaignName: 'java.lang.String',
    top3: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable>',
    flop3: 'java.util.List<fr.hiji.metier.reporting.dto.pdf180.PodiumOrderedGesteObservable>'
};
