// ENTITY fr.hiji.metier.home.dto.front.FormationSessionExpectationsManagerDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserLiteDTO } from './UserLiteDTO';

export class FormationSessionExpectationsManagerDTO {

  idFormation: number | null = 0;
  formationName: MultilingualString | null = new MultilingualString();
  idFormationSession: number | null = 0;
  formationSessionName: string | null = '';
  user: UserLiteDTO | null = null;

  constructor(init?:Partial<FormationSessionExpectationsManagerDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.home.dto.front.FormationSessionExpectationsManagerDTO'] = FormationSessionExpectationsManagerDTO;

reverseMapping[FormationSessionExpectationsManagerDTO.name] = 'fr.hiji.metier.home.dto.front.FormationSessionExpectationsManagerDTO';

fields['fr.hiji.metier.home.dto.front.FormationSessionExpectationsManagerDTO']  = {
    idFormation: 'java.lang.Integer',
    formationName: 'com.ic2.sc.MultilingualString',
    idFormationSession: 'java.lang.Integer',
    formationSessionName: 'java.lang.String',
    user: 'fr.hiji.metier.user.dto.UserLiteDTO'
};
