// ENTITY fr.hiji.metier.referential.dto.ObservableGestureLiteBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { Criteria } from './Criteria';

export class ObservableGestureLiteBODTO {

  idObservableGesture: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  criteria: Criteria | null = null;
  idsTags: number[] | null = [];
  del: boolean | null = false;

  constructor(init?:Partial<ObservableGestureLiteBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.ObservableGestureLiteBODTO'] = ObservableGestureLiteBODTO;

reverseMapping[ObservableGestureLiteBODTO.name] = 'fr.hiji.metier.referential.dto.ObservableGestureLiteBODTO';

fields['fr.hiji.metier.referential.dto.ObservableGestureLiteBODTO']  = {
    idObservableGesture: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    criteria: 'fr.hiji.metier.referential.entities.Criteria',
    idsTags: 'java.util.List<java.lang.Integer>',
    del: 'java.lang.Boolean'
};
