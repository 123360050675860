// ENTITY fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationOG generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventAnimationOG {

  idFormationEventAnimation: number | null = 0;
  idObservableGesture: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<FormationEventAnimationOG>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationOG'] = FormationEventAnimationOG;

reverseMapping[FormationEventAnimationOG.name] = 'fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationOG';

fields['fr.hiji.metier.mif.entities.event.animation.FormationEventAnimationOG']  = {
    idFormationEventAnimation: 'java.lang.Integer',
    idObservableGesture: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
