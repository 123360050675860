// ENTITY fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizQuestionsPerTag generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizQuestionsPerTag {

  idModality: number | null = 0;
  idTag: number | null = 0;
  nb: number | null = 0;

  constructor(init?:Partial<ModalityQuizQuestionsPerTag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizQuestionsPerTag'] = ModalityQuizQuestionsPerTag;

reverseMapping[ModalityQuizQuestionsPerTag.name] = 'fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizQuestionsPerTag';

fields['fr.hiji.metier.certif.entities.modality.quiz.ModalityQuizQuestionsPerTag']  = {
    idModality: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    nb: 'java.lang.Integer'
};
