// ENTITY fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { CampaignPDIFormation } from './CampaignPDIFormation';

export class CampaignPDIFormationBODTO {

  formation: CampaignPDIFormation | null = null;
  tagName: MultilingualString | null = new MultilingualString();
  tagDel: boolean | null = false;

  constructor(init?:Partial<CampaignPDIFormationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO'] = CampaignPDIFormationBODTO;

reverseMapping[CampaignPDIFormationBODTO.name] = 'fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO';

fields['fr.hiji.metier.campaign.dto.back.pdi.CampaignPDIFormationBODTO']  = {
    formation: 'fr.hiji.metier.campaign.entities.pdi.CampaignPDIFormation',
    tagName: 'com.ic2.sc.MultilingualString',
    tagDel: 'java.lang.Boolean'
};
