// ENTITY fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { Organisation } from './Organisation';
import { CertificationSessionStatsLiteDTO } from './CertificationSessionStatsLiteDTO';
import { CertificationLiteDTO } from './CertificationLiteDTO';

export class CertificationStatsFiltersBODTO {

  certifications: CertificationLiteDTO[] | null = [];
  sessions: CertificationSessionStatsLiteDTO[] | null = [];
  assessors: UserFuncAvatarDTO[] | null = [];
  user: UserFuncAvatarDTO | null = null;
  organisations: Organisation[] | null = [];
  start: Date | null = null;
  end: Date | null = null;

  constructor(init?:Partial<CertificationStatsFiltersBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO'] = CertificationStatsFiltersBODTO;

reverseMapping[CertificationStatsFiltersBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.CertificationStatsFiltersBODTO']  = {
    certifications: 'java.util.List<fr.hiji.metier.certif.dto.front.CertificationLiteDTO>',
    sessions: 'java.util.List<fr.hiji.metier.certif.dto.back.stats.filters.CertificationSessionStatsLiteDTO>',
    assessors: 'java.util.List<fr.hiji.metier.user.dto.front.UserFuncAvatarDTO>',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO',
    organisations: 'java.util.List<fr.hiji.metier.reseau.entities.Organisation>',
    start: 'java.time.Instant',
    end: 'java.time.Instant'
};
