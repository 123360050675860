// ENTITY fr.hiji.metier.dashboard.dto.DashboardFormationBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class DashboardFormationBODTO {

  idFormation: number | null = 0;
  nameFormation: MultilingualString | null = new MultilingualString();
  idFormationSession: number | null = 0;
  nameSession: string | null = '';
  nbTarget: number | null = 0;
  trainers: string[] | null = [];

  constructor(init?:Partial<DashboardFormationBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.dashboard.dto.DashboardFormationBODTO'] = DashboardFormationBODTO;

reverseMapping[DashboardFormationBODTO.name] = 'fr.hiji.metier.dashboard.dto.DashboardFormationBODTO';

fields['fr.hiji.metier.dashboard.dto.DashboardFormationBODTO']  = {
    idFormation: 'java.lang.Integer',
    nameFormation: 'com.ic2.sc.MultilingualString',
    idFormationSession: 'java.lang.Integer',
    nameSession: 'java.lang.String',
    nbTarget: 'java.lang.Integer',
    trainers: 'java.util.List<java.lang.String>'
};
