// ENTITY fr.hiji.metier.ema.dto.front.EMASkillMessageDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { EMASkillMessage } from './EMASkillMessage';

export class EMASkillMessageDTO {

  emaSkillMessage: EMASkillMessage | null = null;
  tagName: MultilingualString | null = new MultilingualString();

  constructor(init?:Partial<EMASkillMessageDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.ema.dto.front.EMASkillMessageDTO'] = EMASkillMessageDTO;

reverseMapping[EMASkillMessageDTO.name] = 'fr.hiji.metier.ema.dto.front.EMASkillMessageDTO';

fields['fr.hiji.metier.ema.dto.front.EMASkillMessageDTO']  = {
    emaSkillMessage: 'fr.hiji.metier.ema.entities.EMASkillMessage',
    tagName: 'com.ic2.sc.MultilingualString'
};
