// ENTITY fr.hiji.metier.referential.entities.ObservableGesture generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class ObservableGesture {

  idObservableGesture: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  benchmarkCode: string | null = '';
  idCriteria: number | null = 0;
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = null;
  del: boolean | null = false;

  constructor(init?:Partial<ObservableGesture>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.ObservableGesture'] = ObservableGesture;

reverseMapping[ObservableGesture.name] = 'fr.hiji.metier.referential.entities.ObservableGesture';

fields['fr.hiji.metier.referential.entities.ObservableGesture']  = {
    idObservableGesture: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    benchmarkCode: 'java.lang.String',
    idCriteria: 'java.lang.Integer',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
