// ENTITY fr.hiji.metier.mif.dto.front.quiz.QuizResultMIFDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { UserFuncAvatarDTO } from './UserFuncAvatarDTO';
import { FormationQuizOpenEndedAnswer } from './FormationQuizOpenEndedAnswer';
import { FormationSessionQuizAvailable } from './FormationSessionQuizAvailable';
import { ModalityQuizQuestionResultDTO } from './ModalityQuizQuestionResultDTO';
import { FormationQuizQCMAnswer } from './FormationQuizQCMAnswer';
import { FormationQuizAnswerSkillAvg } from './FormationQuizAnswerSkillAvg';
import { FormationQuizAnswer } from './FormationQuizAnswer';

export class QuizResultMIFDTO {

  idFormationSession: number | null = 0;
  idFormationQuiz: number | null = 0;
  quizName: MultilingualString | null = new MultilingualString();
  questions: ModalityQuizQuestionResultDTO[] | null = [];
  openEndedAnswers: FormationQuizOpenEndedAnswer[] | null = [];
  qcmSelectedAnswers: FormationQuizQCMAnswer[] | null = [];
  averageSkills: FormationQuizAnswerSkillAvg[] | null = [];
  formationSessionQuizAvailable: FormationSessionQuizAvailable | null = null;
  formationQuizAnswer: FormationQuizAnswer | null = null;
  user: UserFuncAvatarDTO | null = null;

  constructor(init?:Partial<QuizResultMIFDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.quiz.QuizResultMIFDTO'] = QuizResultMIFDTO;

reverseMapping[QuizResultMIFDTO.name] = 'fr.hiji.metier.mif.dto.front.quiz.QuizResultMIFDTO';

fields['fr.hiji.metier.mif.dto.front.quiz.QuizResultMIFDTO']  = {
    idFormationSession: 'java.lang.Integer',
    idFormationQuiz: 'java.lang.Integer',
    quizName: 'com.ic2.sc.MultilingualString',
    questions: 'java.util.List<fr.hiji.metier.certif.dto.front.modality.quiz.result.ModalityQuizQuestionResultDTO>',
    openEndedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizOpenEndedAnswer>',
    qcmSelectedAnswers: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizQCMAnswer>',
    averageSkills: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizAnswerSkillAvg>',
    formationSessionQuizAvailable: 'fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable',
    formationQuizAnswer: 'fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer',
    user: 'fr.hiji.metier.user.dto.front.UserFuncAvatarDTO'
};
