// ENTITY fr.hiji.metier.mif.entities.event.observation.FormationEventObservation generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationEventObservationType } from './FormationEventObservationType';

export class FormationEventObservation {

  idFormationEventObservation: number | null = 0;
  idFormationEvent: number | null = 0;
  idUser: number | null = 0;
  type: FormationEventObservationType | null = null;
  creationDate: Date | null = null;
  startAnsweringDate: Date | null = null;

  constructor(init?:Partial<FormationEventObservation>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.event.observation.FormationEventObservation'] = FormationEventObservation;

reverseMapping[FormationEventObservation.name] = 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservation';

fields['fr.hiji.metier.mif.entities.event.observation.FormationEventObservation']  = {
    idFormationEventObservation: 'java.lang.Integer',
    idFormationEvent: 'java.lang.Integer',
    idUser: 'java.lang.Integer',
    type: 'fr.hiji.metier.mif.entities.event.observation.FormationEventObservationType',
    creationDate: 'java.time.Instant',
    startAnsweringDate: 'java.time.Instant'
};
