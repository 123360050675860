// fr.hiji.metier.mif.services.front.EVAServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { EVAStep } from '../entities/EVAStep';
import { EVADTO } from '../entities/EVADTO';

@Injectable({
  providedIn: 'root',
})
export class EVAService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  planEVA(idFormationSession: number, idUser: number, date: Date): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAService.planEVA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(date, 'java.time.Instant', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getEVA(idFormationSession: number, idUser: number): RpcRequestBuilder<EVADTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAService.getEVA';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.manager.EVADTO');
  }

  updateEVAStep(idFormationSession: number, idUser: number, step: EVAStep): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAService.updateEVAStep';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(step, 'fr.hiji.metier.mif.entities.eva.EVAStep', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  updateEVAComment(idFormationSession: number, idUser: number, comment: string): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'EVAService.updateEVAComment';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(comment, 'java.lang.String', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

}
