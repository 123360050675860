// DB ENUM fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportGenerationType generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class SurveyReportGenerationType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly ALL_GLOBAL_TEAM_VS_GLOBAL = new SurveyReportGenerationType(1, "Tous les rapports éligibles équipe globale vs global", "ALL_GLOBAL_TEAM_VS_GLOBAL");
  static readonly ALL_GLOBAL_TEAM_VS_ORG = new SurveyReportGenerationType(2, "Tous les rapports éligibles équipe globale vs organisation", "ALL_GLOBAL_TEAM_VS_ORG");
  static readonly ALL_DIRECT_TEAM_VS_GLOBAL = new SurveyReportGenerationType(3, "Tous les rapports éligibles équipe directe vs global", "ALL_DIRECT_TEAM_VS_GLOBAL");
  static readonly ALL_DIRECT_TEAM_VS_ORG = new SurveyReportGenerationType(4, "Tous les rapports éligibles équipe directe vs organisation", "ALL_DIRECT_TEAM_VS_ORG");
  static readonly ALL_ORG_VS_GLOBAL = new SurveyReportGenerationType(5, "Tous les rapports éligibles organisation vs global", "ALL_ORG_VS_GLOBAL");
  static readonly ALL_ORG_VS_PARENT_ORG = new SurveyReportGenerationType(6, "Tous les rapports éligibles organisation vs organisation parente", "ALL_ORG_VS_PARENT_ORG");
  static readonly VERSUS = new SurveyReportGenerationType(7, "Un rapport versus avec choix des filtres", "VERSUS");

  static readonly values = [
    SurveyReportGenerationType.ALL_GLOBAL_TEAM_VS_GLOBAL,
 
    SurveyReportGenerationType.ALL_GLOBAL_TEAM_VS_ORG,
 
    SurveyReportGenerationType.ALL_DIRECT_TEAM_VS_GLOBAL,
 
    SurveyReportGenerationType.ALL_DIRECT_TEAM_VS_ORG,
 
    SurveyReportGenerationType.ALL_ORG_VS_GLOBAL,
 
    SurveyReportGenerationType.ALL_ORG_VS_PARENT_ORG,
 
    SurveyReportGenerationType.VERSUS
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of SurveyReportGenerationType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of SurveyReportGenerationType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportGenerationType'] = SurveyReportGenerationType;
reverseMapping[SurveyReportGenerationType.name] = 'fr.hiji.metier.campaign.dto.back.survey.reports.SurveyReportGenerationType';
