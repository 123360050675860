// ENTITY fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class ModalityQuizExamSkillAvg {

  idExam: number | null = 0;
  idTag: number | null = 0;
  score: number | null = 0;
  total: number | null = 0;

  constructor(init?:Partial<ModalityQuizExamSkillAvg>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg'] = ModalityQuizExamSkillAvg;

reverseMapping[ModalityQuizExamSkillAvg.name] = 'fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg';

fields['fr.hiji.metier.certif.entities.modality.quiz.answer.ModalityQuizExamSkillAvg']  = {
    idExam: 'java.lang.Integer',
    idTag: 'java.lang.Integer',
    score: 'java.lang.Double',
    total: 'java.lang.Double'
};
