// ENTITY fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuestionStatsBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { QuestionQCM } from './QuestionQCM';
import { QuestionQCMAnswer } from './QuestionQCMAnswer';
import { QuestionFlatTag } from './QuestionFlatTag';
import { Question } from './Question';

export class CertificationQuestionStatsBODTO {

  question: Question | null = null;
  questionQCM: QuestionQCM | null = null;
  tags: QuestionFlatTag[] | null = [];
  answers: QuestionQCMAnswer[] | null = [];
  nbAnswers: number | null = 0;
  nbGoodAnswers: number | null = 0;
  average: number | null = null;
  questionDeletedFromQuiz: boolean | null = false;

  constructor(init?:Partial<CertificationQuestionStatsBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuestionStatsBODTO'] = CertificationQuestionStatsBODTO;

reverseMapping[CertificationQuestionStatsBODTO.name] = 'fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuestionStatsBODTO';

fields['fr.hiji.metier.certif.dto.back.stats.quiz.CertificationQuestionStatsBODTO']  = {
    question: 'fr.hiji.metier.referential.entities.quiz.Question',
    questionQCM: 'fr.hiji.metier.referential.entities.quiz.QuestionQCM',
    tags: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionFlatTag>',
    answers: 'java.util.List<fr.hiji.metier.referential.entities.quiz.QuestionQCMAnswer>',
    nbAnswers: 'java.lang.Integer',
    nbGoodAnswers: 'java.lang.Integer',
    average: 'java.lang.Double',
    questionDeletedFromQuiz: 'java.lang.Boolean'
};
