// ENTITY fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';
import { HighlightType } from './HighlightType';

export class RepositoryObservableGestureBODTO {

  order: number | null = 0;
  highlight: HighlightType | null = null;
  benchmarkCode: string | null = '';
  idObservableGesture: number | null = 0;
  observableGestureName: MultilingualString | null = new MultilingualString();
  observableGestureDescription: MultilingualString | null = new MultilingualString();
  observableGestureDel: boolean | null = false;
  idCriteria: number | null = 0;
  idsTags: number[] | null = [];
  added: boolean | null = false;
  updated: boolean | null = false;
  deleted: boolean | null = false;

  constructor(init?:Partial<RepositoryObservableGestureBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO'] = RepositoryObservableGestureBODTO;

reverseMapping[RepositoryObservableGestureBODTO.name] = 'fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO';

fields['fr.hiji.metier.referential.dto.RepositoryObservableGestureBODTO']  = {
    order: 'java.lang.Integer',
    highlight: 'fr.hiji.metier.referential.entities.HighlightType',
    benchmarkCode: 'java.lang.String',
    idObservableGesture: 'java.lang.Integer',
    observableGestureName: 'com.ic2.sc.MultilingualString',
    observableGestureDescription: 'com.ic2.sc.MultilingualString',
    observableGestureDel: 'java.lang.Boolean',
    idCriteria: 'java.lang.Integer',
    idsTags: 'java.util.List<java.lang.Integer>',
    added: 'java.lang.Boolean',
    updated: 'java.lang.Boolean',
    deleted: 'java.lang.Boolean'
};
