// ENTITY fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { HijiEmail } from './HijiEmail';

export class FormationSessionEmailConfig {

  idFormationSession: number | null = 0;
  emailType: HijiEmail | null = null;
  enabled: boolean | null = false;
  manual: boolean | null = false;
  manualDate: boolean | null = false;
  date: Date | null = null;
  sent: boolean | null = false;
  sentDate: Date | null = null;
  instantReminder: boolean | null = false;
  idUserReminder: number | null = 0;
  nbParticipantsReminder: number | null = 0;

  constructor(init?:Partial<FormationSessionEmailConfig>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig'] = FormationSessionEmailConfig;

reverseMapping[FormationSessionEmailConfig.name] = 'fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig';

fields['fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig']  = {
    idFormationSession: 'java.lang.Integer',
    emailType: 'fr.hiji.metier.tools.email.HijiEmail',
    enabled: 'java.lang.Boolean',
    manual: 'java.lang.Boolean',
    manualDate: 'java.lang.Boolean',
    date: 'java.time.Instant',
    sent: 'java.lang.Boolean',
    sentDate: 'java.time.Instant',
    instantReminder: 'java.lang.Boolean',
    idUserReminder: 'java.lang.Integer',
    nbParticipantsReminder: 'java.lang.Integer'
};
