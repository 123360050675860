// ENTITY fr.hiji.metier.visualize.dto.front.VisualizeDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { VizSurveyDTO } from './VizSurveyDTO';
import { VizBilanDTO } from './VizBilanDTO';
import { ExchangeItemDTO } from './ExchangeItemDTO';
import { VizInquiryAnswerDTO } from './VizInquiryAnswerDTO';
import { FeedbackDTO } from './FeedbackDTO';
import { VizInquiryDTO } from './VizInquiryDTO';

export class VisualizeDTO {

  nextMonthToLoad: Date | null = null;
  feedbacks: FeedbackDTO[] | null = [];
  exchanges: ExchangeItemDTO[] | null = [];
  bilans: VizBilanDTO[] | null = [];
  surveys: VizSurveyDTO[] | null = [];
  inquiries: VizInquiryDTO[] | null = [];
  inquiryAnswers: VizInquiryAnswerDTO[] | null = [];

  constructor(init?:Partial<VisualizeDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.visualize.dto.front.VisualizeDTO'] = VisualizeDTO;

reverseMapping[VisualizeDTO.name] = 'fr.hiji.metier.visualize.dto.front.VisualizeDTO';

fields['fr.hiji.metier.visualize.dto.front.VisualizeDTO']  = {
    nextMonthToLoad: 'java.time.Instant',
    feedbacks: 'java.util.List<fr.hiji.metier.fbs.dto.FeedbackDTO>',
    exchanges: 'java.util.List<fr.hiji.metier.campaign.dto.front.ExchangeItemDTO>',
    bilans: 'java.util.List<fr.hiji.metier.visualize.dto.front.VizBilanDTO>',
    surveys: 'java.util.List<fr.hiji.metier.visualize.dto.front.VizSurveyDTO>',
    inquiries: 'java.util.List<fr.hiji.metier.visualize.dto.front.VizInquiryDTO>',
    inquiryAnswers: 'java.util.List<fr.hiji.metier.visualize.dto.front.VizInquiryAnswerDTO>'
};
