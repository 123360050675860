// ENTITY fr.hiji.metier.user.dto.back.UserItemBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class UserItemBODTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  email: string | null = '';
  regions: string[] | null = [];
  etablissements: string[] | null = [];
  functionName: string | null = '';
  report: string | null = '';
  inMyPerimeter: boolean | null = false;
  del: boolean | null = false;

  constructor(init?:Partial<UserItemBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.user.dto.back.UserItemBODTO'] = UserItemBODTO;

reverseMapping[UserItemBODTO.name] = 'fr.hiji.metier.user.dto.back.UserItemBODTO';

fields['fr.hiji.metier.user.dto.back.UserItemBODTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    email: 'java.lang.String',
    regions: 'java.util.List<java.lang.String>',
    etablissements: 'java.util.List<java.lang.String>',
    functionName: 'java.lang.String',
    report: 'java.lang.String',
    inMyPerimeter: 'java.lang.Boolean',
    del: 'java.lang.Boolean'
};
