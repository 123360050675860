// ENTITY fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FormationSessionQuizAvailable } from './FormationSessionQuizAvailable';
import { FormationSessionTrainerBODTO } from './FormationSessionTrainerBODTO';
import { FormationSessionDate } from './FormationSessionDate';
import { FormationSessionEmailConfig } from './FormationSessionEmailConfig';
import { FormationSessionDiffusionListBODTO } from './FormationSessionDiffusionListBODTO';
import { FormationSession } from './FormationSession';
import { FormationQuiz } from './FormationQuiz';
import { FormationQuizAnswer } from './FormationQuizAnswer';
import { FormationSessionParticipantBODTO } from './FormationSessionParticipantBODTO';

export class FormationSessionBODTO {

  formationSession: FormationSession | null = null;
  dates: FormationSessionDate[] | null = [];
  emailConfig: FormationSessionEmailConfig[] | null = [];
  trainers: FormationSessionTrainerBODTO[] | null = [];
  participants: FormationSessionParticipantBODTO[] | null = [];
  availableQuizs: FormationSessionQuizAvailable[] | null = [];
  formationQuizs: FormationQuiz[] | null = [];
  listFormationSessionDiffusionList: FormationSessionDiffusionListBODTO[] | null = [];
  quizAnswers: FormationQuizAnswer[] | null = [];
  userCreator: string | null = '';
  userUpdater: string | null = '';

  constructor(init?:Partial<FormationSessionBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO'] = FormationSessionBODTO;

reverseMapping[FormationSessionBODTO.name] = 'fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO';

fields['fr.hiji.metier.mif.dto.back.formationsession.FormationSessionBODTO']  = {
    formationSession: 'fr.hiji.metier.mif.entities.session.FormationSession',
    dates: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionDate>',
    emailConfig: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionEmailConfig>',
    trainers: 'java.util.List<fr.hiji.metier.mif.dto.back.formationsession.FormationSessionTrainerBODTO>',
    participants: 'java.util.List<fr.hiji.metier.mif.dto.back.formationsession.FormationSessionParticipantBODTO>',
    availableQuizs: 'java.util.List<fr.hiji.metier.mif.entities.session.FormationSessionQuizAvailable>',
    formationQuizs: 'java.util.List<fr.hiji.metier.mif.entities.formation.FormationQuiz>',
    listFormationSessionDiffusionList: 'java.util.List<fr.hiji.metier.mif.dto.back.formationsession.FormationSessionDiffusionListBODTO>',
    quizAnswers: 'java.util.List<fr.hiji.metier.mif.entities.quiz.FormationQuizAnswer>',
    userCreator: 'java.lang.String',
    userUpdater: 'java.lang.String'
};
