// ENTITY fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypeBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionType } from './OpinionType';

export class NbAnswerPerOpinionTypeBODTO {

  opinionType: OpinionType | null = null;
  nbAnswers: number | null = 0;
  nbPrint: number | null = 0;

  constructor(init?:Partial<NbAnswerPerOpinionTypeBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypeBODTO'] = NbAnswerPerOpinionTypeBODTO;

reverseMapping[NbAnswerPerOpinionTypeBODTO.name] = 'fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypeBODTO';

fields['fr.hiji.metier.opinion.dto.back.NbAnswerPerOpinionTypeBODTO']  = {
    opinionType: 'fr.hiji.metier.opinion.entities.OpinionType',
    nbAnswers: 'java.lang.Integer',
    nbPrint: 'java.lang.Integer'
};
