// fr.hiji.metier.referential.services.back.ImportExportQuizBOServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:49 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { Quiz } from '../entities/Quiz';

@Injectable({
  providedIn: 'root',
})
export class ImportExportQuizBOService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  downloadModele(): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportQuizBOService.downloadModele';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

  importQuiz(): RpcRequestBuilder<Quiz> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportQuizBOService.importQuiz';
    req.params = [];
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.referential.entities.quiz.Quiz');
  }

  downloadQuiz(idQuiz: number): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'ImportExportQuizBOService.downloadQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'java.io.InputStream');
  }

}
