// ENTITY fr.hiji.metier.reseau.entities.Fonction generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Fonction {

  idFonction: number | null = 0;
  name: string | null = '';
  ref: string | null = '';
  description: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  del: boolean | null = false;

  constructor(init?:Partial<Fonction>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reseau.entities.Fonction'] = Fonction;

reverseMapping[Fonction.name] = 'fr.hiji.metier.reseau.entities.Fonction';

fields['fr.hiji.metier.reseau.entities.Fonction']  = {
    idFonction: 'java.lang.Integer',
    name: 'java.lang.String',
    ref: 'java.lang.String',
    description: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    del: 'java.lang.Boolean'
};
