// ENTITY fr.hiji.metier.opinion.dto.front.OpinionDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { OpinionQuestion } from './OpinionQuestion';
import { OpinionAnswer } from './OpinionAnswer';

export class OpinionDTO {

  listOpinionQuestion: OpinionQuestion[] | null = [];
  listOpinionAnswer: OpinionAnswer[] | null = [];
  opinionSuggestion: string | null = '';
  showQuestionToUser: boolean | null = false;

  constructor(init?:Partial<OpinionDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.opinion.dto.front.OpinionDTO'] = OpinionDTO;

reverseMapping[OpinionDTO.name] = 'fr.hiji.metier.opinion.dto.front.OpinionDTO';

fields['fr.hiji.metier.opinion.dto.front.OpinionDTO']  = {
    listOpinionQuestion: 'java.util.List<fr.hiji.metier.opinion.entities.OpinionQuestion>',
    listOpinionAnswer: 'java.util.List<fr.hiji.metier.opinion.entities.OpinionAnswer>',
    opinionSuggestion: 'java.lang.String',
    showQuestionToUser: 'java.lang.Boolean'
};
