// ENTITY fr.hiji.metier.referential.entities.Tag generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields, MultilingualString } from '@ic2/ic2-lib';

export class Tag {

  idTag: number | null = 0;
  name: MultilingualString | null = new MultilingualString();
  description: MultilingualString | null = new MultilingualString();
  colorCode: string | null = '';
  creationDate: Date | null = null;
  idUserCreator: number | null = 0;
  updateDate: Date | null = null;
  idUserUpdater: number | null = 0;
  idParent: number | null = 0;
  order: number | null = 0;
  showInRadar: boolean | null = false;
  hideInFO: boolean | null = false;
  benchmarkCode: string | null = '';
  del: boolean | null = false;

  constructor(init?:Partial<Tag>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.referential.entities.Tag'] = Tag;

reverseMapping[Tag.name] = 'fr.hiji.metier.referential.entities.Tag';

fields['fr.hiji.metier.referential.entities.Tag']  = {
    idTag: 'java.lang.Integer',
    name: 'com.ic2.sc.MultilingualString',
    description: 'com.ic2.sc.MultilingualString',
    colorCode: 'java.lang.String',
    creationDate: 'java.time.Instant',
    idUserCreator: 'java.lang.Integer',
    updateDate: 'java.time.Instant',
    idUserUpdater: 'java.lang.Integer',
    idParent: 'java.lang.Integer',
    order: 'java.lang.Integer',
    showInRadar: 'java.lang.Boolean',
    hideInFO: 'java.lang.Boolean',
    benchmarkCode: 'java.lang.String',
    del: 'java.lang.Boolean'
};
