// DB ENUM fr.hiji.metier.ema.entities.EMAType generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping } from '@ic2/ic2-lib';

export class EMAType {
  static readonly enum: boolean = true;
  
  constructor(public id: number, public lib: string, public name: string) {
  }
  
  static readonly TIME_PARTNER = new EMAType(1, "Time partner", "TIME_PARTNER");
  static readonly ONE_TO_ONE = new EMAType(2, "One to one", "ONE_TO_ONE");

  static readonly values = [
    EMAType.TIME_PARTNER,
 
    EMAType.ONE_TO_ONE
 
  ];

  public toString(): string {
    return this.lib;
  }
  public static map(name: string) {
    for (const e of EMAType.values)
      if (e.name === name)
        return e;
    return null;
  }
  public static mapFromId(id: number) {
    for (const key of EMAType.values)
      if (key.id === id)
        return key;
    return null;
  }
}

mapping['fr.hiji.metier.ema.entities.EMAType'] = EMAType;
reverseMapping[EMAType.name] = 'fr.hiji.metier.ema.entities.EMAType';
