// ENTITY fr.hiji.metier.reporting.entities._180.Report180ExchangeRate generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:59 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class Report180ExchangeRate {

  idReport180Config: number | null = 0;
  date: Date | null = null;
  idOrganisation: number | null = 0;
  idUserManager: number | null = 0;
  nbUser: number | null = 0;
  nbExchangeUserUnique: number | null = 0;
  nbExchangeTotal: number | null = 0;

  constructor(init?:Partial<Report180ExchangeRate>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.reporting.entities._180.Report180ExchangeRate'] = Report180ExchangeRate;

reverseMapping[Report180ExchangeRate.name] = 'fr.hiji.metier.reporting.entities._180.Report180ExchangeRate';

fields['fr.hiji.metier.reporting.entities._180.Report180ExchangeRate']  = {
    idReport180Config: 'java.lang.Integer',
    date: 'java.time.LocalDate',
    idOrganisation: 'java.lang.Integer',
    idUserManager: 'java.lang.Integer',
    nbUser: 'java.lang.Integer',
    nbExchangeUserUnique: 'java.lang.Integer',
    nbExchangeTotal: 'java.lang.Integer'
};
