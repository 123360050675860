// ENTITY fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:57 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class FormationEventSatisfactionOgsOrderDTO {

  idObservableGesture: number | null = 0;
  order: number | null = 0;

  constructor(init?:Partial<FormationEventSatisfactionOgsOrderDTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO'] = FormationEventSatisfactionOgsOrderDTO;

reverseMapping[FormationEventSatisfactionOgsOrderDTO.name] = 'fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO';

fields['fr.hiji.metier.mif.dto.front.event.FormationEventSatisfactionOgsOrderDTO']  = {
    idObservableGesture: 'java.lang.Integer',
    order: 'java.lang.Integer'
};
