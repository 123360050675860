// fr.hiji.metier.mif.services.front.FormationQuizServiceI generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:50 UTC 2024
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { IRPC, JSONSerializer, JSONRpcRequest, JSONRpcResponse, RpcRequestBuilder } from '@ic2/ic2-lib';
import { QuizResultsTrainerMIFDTO } from '../entities/QuizResultsTrainerMIFDTO';
import { QuizMIFDTO } from '../entities/QuizMIFDTO';
import { QuizResultMIFDTO } from '../entities/QuizResultMIFDTO';

@Injectable({
  providedIn: 'root',
})
export class FormationQuizService {
  constructor(@Inject(IRPC) private irpc: IRPC) {}
  answerQuiz(dto: QuizMIFDTO): RpcRequestBuilder<void> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizService.answerQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(dto, 'fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, null);
  }

  getQuizResult(idFormationSession: number, idFormationQuiz: number, idUser: number): RpcRequestBuilder<QuizResultMIFDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizService.getQuizResult';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idUser, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.quiz.QuizResultMIFDTO');
  }

  getQuizTrainer(idFormationSession: number, idFormationQuiz: number): RpcRequestBuilder<QuizResultsTrainerMIFDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizService.getQuizTrainer';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.quiz.QuizResultsTrainerMIFDTO');
  }

  getQuiz(idFormationSession: number, idFormationQuiz: number): RpcRequestBuilder<QuizMIFDTO> {
    const req = new JSONRpcRequest();
    req.serviceDotMethod = 'FormationQuizService.getQuiz';
    req.params = [];
    req.params.push(
      JSONSerializer.toJSON(idFormationSession, 'java.lang.Integer', null, false)
    );
    req.params.push(
      JSONSerializer.toJSON(idFormationQuiz, 'java.lang.Integer', null, false)
    );
    return new RpcRequestBuilder(this.irpc, req, 'fr.hiji.metier.mif.dto.front.quiz.QuizMIFDTO');
  }

}
