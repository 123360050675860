// ENTITY fr.hiji.metier.fbs.entities.Feedback generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';
import { FeedbackRecipientType } from './FeedbackRecipientType';

export class Feedback {

  idFeedback: number | null = 0;
  idFeedbackCategory: number | null = 0;
  idUserSender: number | null = 0;
  recipientType: FeedbackRecipientType | null = null;
  groupName: string | null = '';
  message: string | null = '';
  creationDate: Date | null = null;

  constructor(init?:Partial<Feedback>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.fbs.entities.Feedback'] = Feedback;

reverseMapping[Feedback.name] = 'fr.hiji.metier.fbs.entities.Feedback';

fields['fr.hiji.metier.fbs.entities.Feedback']  = {
    idFeedback: 'java.lang.Integer',
    idFeedbackCategory: 'java.lang.Integer',
    idUserSender: 'java.lang.Integer',
    recipientType: 'fr.hiji.metier.fbs.entities.FeedbackRecipientType',
    groupName: 'java.lang.String',
    message: 'java.lang.String',
    creationDate: 'java.time.Instant'
};
