// ENTITY fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByManagerBODTO generated by ic2 0.0.1-SNAPSHOT at Mon Oct 14 08:30:58 UTC 2024

import { mapping, reverseMapping, fields } from '@ic2/ic2-lib';

export class SurveyStatsScoreByManagerBODTO {

  idUser: number | null = 0;
  firstName: string | null = '';
  lastName: string | null = '';
  nbAnswers: number | null = 0;
  nbUser: number | null = 0;
  avg: number | null = null;
  nbOGObs: number | null = 0;
  nbValue1: number | null = 0;
  nbValue2: number | null = 0;
  nbValue3: number | null = 0;
  nbValue4: number | null = 0;
  nbValue5: number | null = 0;
  nps: number | null = 0;

  constructor(init?:Partial<SurveyStatsScoreByManagerBODTO>) {
    Object.assign(this, init);
  }
  
  toString() {
    return JSON.stringify(this);
  }

}

mapping['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByManagerBODTO'] = SurveyStatsScoreByManagerBODTO;

reverseMapping[SurveyStatsScoreByManagerBODTO.name] = 'fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByManagerBODTO';

fields['fr.hiji.metier.campaign.dto.back.survey.survey_stats.SurveyStatsScoreByManagerBODTO']  = {
    idUser: 'java.lang.Integer',
    firstName: 'java.lang.String',
    lastName: 'java.lang.String',
    nbAnswers: 'java.lang.Integer',
    nbUser: 'java.lang.Integer',
    avg: 'java.lang.Double',
    nbOGObs: 'java.lang.Integer',
    nbValue1: 'java.lang.Integer',
    nbValue2: 'java.lang.Integer',
    nbValue3: 'java.lang.Integer',
    nbValue4: 'java.lang.Integer',
    nbValue5: 'java.lang.Integer',
    nps: 'java.lang.Double'
};
